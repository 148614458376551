<template>
  <div class="q-handle full-page">
    <div class="handle-title">
      <a href="javascript:;" class="exit" @click="$router.push('/pc')">
        <img :src="iconExit" alt="" class="icon-exit" />
        退出统计
      </a>
      个人问卷/问卷标题
    </div>
    <div class="step-wrapper">
      <div class="step-container">
        <div
          v-for="step in stepList"
          :key="step.type"
          :class="{ active: stepActive === step.type }"
          class="step-item"
          @click="toRouter(step.name,step.type)"
        >
          {{ step.label }}
        </div>
      </div>
      <div class="submit-ctrl">
        <el-button
          size="mini"
          @click="push"
        >投放</el-button>
      </div>
    </div>
    <div class="handle-body">
      <div class="left">
        <div class="menus">
          <div
            v-for="menu in menus"
            :key="menu.type"
            :class="{ active: menuActive === menu.type }"
            class="menu"
            @click="(menuActive = menu.type), $router.push(menu.path)"
          >
            <component
              :is="menu.type"
              :active="menuActive === menu.type"
              class="block"
            />
            {{ menu.label }}
          </div>
        </div>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import iconExit from "@/assets/icon-exit.png";
import apis from '@/apis';
import outline from "../svg/icon-outline";
import question from "../svg/icon-question";
import setting from "../svg/icon-setting";

export default {
  name: "statistics",
  components: {
    outline,
    question,
    setting,
  },
  data() {
    return {
      value: true,
      menuActive: "question",
      iconExit,
      stepActive: "statistics",
      active: 1,
      stepList: [
        {
          label: "编辑",
          type: "edit",
          name: "q-handle",
        },
        {
          label: "设置",
          type: "setting",
        },
        {
          label: "分享",
          type: "share",
          name: 'link'
        },
        {
          label: "统计",
          type: "statistics",
          name: "report",
        },
      ],
      menus: [
        {
          label: "统计报告",
          type: "question",
          path: "/pc/q-statustucs/report",
        },
      ],
    };
  },
  methods: {
    toRouter(name, type) {
      const query = {
        type,
        id: this.$route.query.id,
        pos: this.active,
      };
      this.$router.push({
        name: name,
        query,
      });
    },
    push() {
      apis.questionSend({
        id: localStorage.questionId
      }).then(() => {
        this.$message({
          type: 'success',
          message: '投放成功!'
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}
.handle-title {
  position: relative;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 56px;
  background: #ffffff;
  color: #3d3d3d;

  .exit {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    text-decoration: none;
    font-size: 14px;
    color: #3d3d3d;

    .icon-exit {
      display: block;
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }
}

.step-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  padding: 19px 0;
  background: #fff;
  .step-container {
    display: flex;
  }

  .step-item {
    display: block;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    color: #7e7e7e;

    &:last-child::after {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      margin: 0 20px;
      width: 10px;
      height: 10px;
      background: url("~@/assets/icon-step.png");
      background-size: 100% 100%;
    }

    &.active {
      color: #008755;

      &::before {
        content: "";
        position: absolute;
        bottom: -19px;
        left: 0;
        width: 28px;
        height: 2px;
        background: #008755;
        border-radius: 10px 10px 10px 10px;
      }
    }
  }

  .submit-ctrl {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #3d3d3d;
  }
}

.handle-body {
  display: flex;
  padding: 10px 16px;
  height: calc(100% - 120px);

  .left,
  .content {
    height: 90%;
  }

  .left {
    width: 60px;
    box-shadow: 0px 4px 10px 0px #f2f2f2;
    border-radius: 4px 4px 4px 4px;
    background: #fff;
    display: flex;
  }
  .content {
    margin: 0 0 0 10px;
    flex: 1;
    overflow: auto;
  }

  .menus {
    padding: 30px 16px;
    height: 100%;

    .block {
      display: block;
      margin: 0 auto 4px;
      width: 18px;
      height: 18px;
    }
    .menu {
      margin-bottom: 30px;
      font-size: 13px;
      color: #3d3d3d;
      cursor: pointer;

      &.active {
        color: #008755;
      }
    }
  }
}

::v-deep {
  .el-tabs__item {
    &.is-active,
    &:hover {
      color: #008755;
    }
  }
  .el-tabs__active-bar {
    background-color: #008755;
  }

  .el-tabs__nav {
    width: 100%;
    text-align: center;
  }

  .el-tabs__active-bar {
    width: 50% !important;
  }
}
</style>
